<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div class="page">
    <!-- Header -->
    <Header :shareBtn="true" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>

    <div class="page-content">
      <div class="card card-style">
        <div class="content">
          <h1 class="mb-0 font-800 color-highlight">Syarat dan Ketentuan</h1>
          <p class="">
            Halaman ini menyatakan syarat penggunaan (“Ketentuan”) di mana Anda
            dapat menggunakan Situs Web <strong>PasJob.com</strong>, dan
            hubungan Anda dengan <strong>PasJob.com</strong>. Harap baca dengan
            cermat karena hal itu memengaruhi hak dan kewajiban Anda di bawah
            hukum. Jika Anda tidak menyetujui Ketentuan ini, mohon untuk tidak
            mendaftar atau menggunakan Situs <strong>PasJob.com</strong>.
            Ketentuan ini berlaku mulai <strong>Februari 2022</strong>. Jika
            Anda memiliki pertanyaan tentang Kondisi dan Ketentuan Penggunaan
            Layanan, silakan hubungi kami di sini
          </p>

          <h5 class="">A. Definisi</h5>
          <ol type="1">
            <li>
              <strong>PasJob.com</strong> : Pengelola situs web
              <strong>PasJob.com</strong>
            </li>
            <li>
              Pemberi kerja : pihak, organisasi, individu, atau perusahaan yang
              menggunakan <strong>PasJob.com</strong> untuk pemenuhan kebutuhan
              tenaga kerja profesionalnya melalui pemasangan iklan lowongan
              kerja/peluang karir pada situs <strong>PasJob.com</strong>
            </li>
            <li>
              Pencari Kerja : pihak atau individu yang menggunakan
              <strong>PasJob.com</strong> untuk melamar pekerjaan atau peluang
              karir dan untuk mendapatkan informasi yang tersedia di
              <strong>PasJob.com</strong> atau link-link lain yang terkait.
            </li>
            <li>
              Pengguna : Mengacu pada individu atau entitas yang menggunakan
              aspek apa pun dari Situs Web <strong>PasJob.com</strong>
            </li>
            <li>
              Anda : Orang (atau entitas atas nama siapa Anda bertindak) yang
              menyetujui Persyaratan ini.
            </li>
          </ol>
          <h5 class="">
            B. Aturan Penggunaan Situs Web PasJob.com
          </h5>
          <ol type="1">
            <li>
              Pengelola situs <strong>PasJob.com</strong> berhak untuk
              menambah/merubah peraturan-peraturan ini dari waktu ke waktu.
            </li>
            <li>
              Situs <strong>PasJob.com</strong> disediakan terutama untuk
              mencari dan memasang iklan lowongan kerja dari berbagai perusahaan
              swasta atau instansi pemerintahan
            </li>
            <li>
              Untuk mendaftar di situs web <strong>PasJob.com</strong>, anda
              harus berumur minimal 15 tahun.
            </li>
            <li>
              Pemasang iklan lowongan kerja diharuskan memberikan informasi yang
              benar dan dapat dipertanggung jawabkan agar pengguna situs
              <strong>PasJob.com</strong> terhindar dari hal-hal yang merugikan.
            </li>
            <li>
              Pengelola <strong>PasJob.com</strong> tidak bertanggung jawab atas
              isi iklan dan seluruh akibat yang ditimbulkan dari segala isi
              iklan yang dimuat dan segala bentuk transaksi yang dilakukan oleh
              pengguna <strong>PasJob.com</strong> oleh iklan lowongan kerja
              tersebut. Pemasang iklan lowongan kerja (employer) dan pencari
              kerja (jobseeker) dengan ini membebaskan pengelola
              <strong>PasJob.com</strong> dari segala tuntutan atas pernyataan
              yang salah, pelanggaran hak cipta, peniruan, pelanggaran privasi
              dan tuntutan-tuntutan lain yang berkaitan dengan iklan lowongan
              kerja yang dipasang.
            </li>
            <li>
              Pengguna tidak diperkenankan untuk melakukan segala bentuk
              kejahatan kriminal, termasuk namun tidak terbatas hanya pada
              kejahatan siber, baik secara langsung maupun tidak langsung,
              melalui <strong>PasJob.com</strong>
            </li>
            <li>
              Pengelola <strong>PasJob.com</strong> berhak melakukan penghapusan
              Iklan anda tanpa pemberitahuan sebelumnya.
            </li>
            <li>
              Di situs <strong>PasJob.com</strong> ini terdapat link, berupa
              banner maupun text, ke situs lain. Pengelola situs
              <strong>PasJob.com</strong> tidak betanggung-jawab atas isi dan
              akibat yang ditimbulkan dari situs-situs tersebut.
            </li>
          </ol>
          <h5 class="">
            C. Ketentuan Tambahan berlaku untuk Pemberi Kerja
          </h5>
          <ol type="1">
            <li>
              Pemberi Kerja wajib menginformasikan identitas asli perusahaannya
              dengan sebenar-benarnya untuk kepentingan pendaftaran akun dan
              penayangan iklan oleh <strong>PasJob.com</strong>. Segala bentuk
              penipuan ataupun pencatutan identitas perusahaan sebagai pengguna
              akan ditindaklanjuti oleh <strong>PasJob.com</strong>.
            </li>
            <li>
              Konten iklan yang dapat ditayangkan pada
              <strong>PasJob.com</strong> hanyalah konten yang berkaitan dengan
              kebutuhan rekrutmen.
            </li>
            <li>
              Tidak diperkenankan untuk menayangkan materi iklan yang mengandung
              unsur pornografi, agama, ras, suku, atau hal lain yang dapat
              menebar kebencian sosial.
            </li>
            <li>
              Tidak diperkenankan pula untuk menayangkan informasi yang rancu,
              menyesatkan, dan/atau melecehkan pihak lain dalam konten iklan.
            </li>
            <li>
              Pemberi Kerja tidak diperkenankan untuk memungut biaya sepeserpun
              dari pencari kerja yang hendak direkrut melalui
              <strong>PasJob.com</strong>; baik sebelum maupun sesudah proses
              interview dilaksanakan; dan dengan alasan apapun, baik untuk biaya
              transportasi, konsumsi, training, seragam, atau hal-hal lainnya.
            </li>
            <li>
              Seluruh konten iklan yang diajukan perlu mendapat persetujuan
              tayang dari <strong>PasJob.com</strong>
            </li>
            <li>
              Pemberi kerja memahami dan sepakat bahwa dalam kondisi kerja sama
              antara <strong>PasJob.com</strong> dengan media lainnya,
              <strong>PasJob.com</strong> berhak untuk menayangkan konten iklan
              Pemberi kerja melalui media-media tersebut tanpa pemberitahuan
              sebelumnya
            </li>
            <li>
              <strong>PasJob.com</strong> tidak mengizinkan iklan pekerjaan yang
              mengiklankan lebih dari 1 posisi pekerjaan di setiap posting
              pekerjaan.
            </li>
            <li>
              Segala bentuk hak yang dimiliki akan dinyatakan gugur secara
              otomatis jika akun / iklan terindikasi penipuan.
            </li>
          </ol>
          <h5 class="">
            D. Ketentuan Tambahan berlaku untuk Pemberi Kerja
          </h5>
          <ol type="1">
            <li>
              <strong>PasJob.com</strong> tidak bertanggung-jawab atas isi iklan
              atau informasi apapun yang dipasang oleh Pemberi kerja di
              <strong>PasJob.com</strong>
            </li>
            <li>
              <strong>PasJob.com</strong> berhak untuk mengedit resume,
              memblokir account dan menolak memberikan layanan kepada Pencari
              Kerja yang dianggap melanggar kebijakan
              <strong>PasJob.com</strong>, di mana interpretasinya menjadi hak
              <strong>PasJob.com</strong> sepenuhnya. Keputusan
              <strong>PasJob.com</strong> dalam hal ini bersifat mutlak dan
              tidak dapat diganggu-gugat.
            </li>
            <li>
              Anda mengetahui dan menyetujui bahwa resume anda akan ditampilkan
              dan digunakan oleh Pemberi kerja untuk keperluan rekrutmen tenaga
              kerja.
            </li>
            <li>
              Anda setuju untuk tidak mencantumkan informasi yang tidak benar,
              menyesatkan, melecehkan, membangkitkan kebencian, memfitnah,
              bersifat diskriminatif terhadap suku, agama dan ras tertentu
              (SARA) ataupun menyinggung prinsip keagamaan.
            </li>
            <li>
              Anda merupakan satu-satunya pihak yang bertanggung-jawab penuh
              atas informasi yang dicantumkan dalam surat lamaran atau resume.
            </li>
            <li>
              Anda setuju untuk tidak menuntut
              <strong>PasJob.com</strong> dan/atau seluruh karyawannya atas
              kerugian apapun yang terjadi akibat penggunaan
              <strong>PasJob.com</strong> atau link-link lain yang terkait.
            </li>
            <li>
              Anda tidak diperkenankan untuk menggunakan informasi yang
              diperoleh dari <strong>PasJob.com</strong> atau link-link lain
              yang terkait untuk tujuan yang melanggar hukum, atau melanggar
              undang-undang hak cipta dan hak intelektual. Pelanggaran terhadap
              ketentuan ini dapat diperkarakan ke pengadilan oleh
              <strong>PasJob.com</strong> dan/atau pihak-pihak lain yang
              dirugikan.
            </li>
            <li>
              Anda sepakat untuk menyetujui syarat dan ketentuan lain yang
              mungkin akan ditambahkan oleh <strong>PasJob.com</strong> dari
              waktu ke waktu tanpa pemberitahuan sebelumnya dari
              <strong>PasJob.com</strong>
            </li>
          </ol>
          <h5 class="">E. Kekayaan Intelektual</h5>
          <ol type="1">
            <li>
              Situs Web <strong>PasJob.com</strong>, Konten
              <strong>PasJob.com</strong>, dan semua hak, kepemilikan, dan
              kepentingan dalam dan ke Situs Web <strong>PasJob.com</strong> dan
              Konten <strong>PasJob.com</strong> adalah milik eksklusif
              <strong>PasJob.com</strong> atau pemberi lisensinya.
            </li>
            <li>
              Tidak diperkenankan mereproduksi, memodifikasi, menyalin atau
              mendistribusikan atau menggunakan untuk tujuan komersial setiap
              materi atau Konten <strong>PasJob.com</strong> di Situs Web
              <strong>PasJob.com</strong> tanpa izin tertulis dari
              <strong>PasJob.com</strong>
            </li>
          </ol>
          <h5 class="">F. Ketersedian Situs Web PasJob.com</h5>
          <ol type="1">
            <li>
              Meskipun kami bertujuan untuk menawarkan layanan terbaik kepada
              Anda, kami tidak berjanji bahwa layanan di Situs Web
              <strong>PasJob.com</strong>
              akan memenuhi kebutuhan Anda.
            </li>
            <li>
              Kami tidak dapat menjamin bahwa Situs Web
              <strong>PasJob.com</strong> akan bebas dari kesalahan, bebas
              kesalahan, atau bahwa Situs Web dan server
              <strong>PasJob.com</strong> bebas dari virus atau mekanisme
              berbahaya lainnya. Jika terjadi kesalahan pada Situs Web
              <strong>PasJob.com</strong>, Anda harus melaporkannya di sini dan
              kami akan berusaha memperbaiki kesalahan tersebut secepat mungkin.
            </li>
            <li>
              Akses Anda ke Situs Web <strong>PasJob.com</strong> terkadang
              dibatasi untuk memungkinkan adanya perbaikan, pemeliharaan atau
              pengenalan konten, fasilitas atau layanan baru. Kami akan berusaha
              memulihkan akses dan / atau layanan secepat mungkin.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { init_template } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer,
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
};
</script>
